const modals = document.querySelectorAll('[data-dialog-open]');
const closeButton = document.querySelectorAll(".modal__close");

modals.forEach(function (modal) {
    modal.addEventListener('click', function () {
        const dialog = document.getElementById(this.dataset.dialogOpen);
        dialog.showModal();
    });
});

closeButton.forEach(function (button) {
    button.addEventListener('click', function () {
        const dialog = document.getElementById(this.dataset.dialogClose);
        dialog.close();
    });
});
