window.addEventListener('DOMContentLoaded', () => {

	const pluginObserver = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			const id = entry.target.getAttribute('id');
			if (entry.intersectionRatio > 0) {
				var selector = document.querySelector(`.festival-menu__item a[href="#${id}"]`);
				if (selector) {
					selector.classList.add('is-active');
				}
			} else {
				var selector = document.querySelector(`.festival-menu__item a[href="#${id}"]`);
				if (selector) {
					selector.classList.remove('is-active');
				}
			}
		});
	});

	// Track all sections that have an `id` applied
    document.querySelectorAll('.festival-section[id]').forEach((section) => {
		pluginObserver.observe(section);
	});

});


