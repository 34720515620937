const dayLink = document.querySelectorAll('[data-schedule-link]');
const daySchedule = document.querySelectorAll('[data-schedule-day]');

dayLink.forEach(function (link) {
    link.addEventListener('click', function () {
        const target = link.dataset.scheduleLink;
        
        // Add is-active classes to day links
        dayLink.forEach(function (item) {
            item.classList.remove('is-active');
        });
        link.classList.add('is-active');

        // Hide/show relevant schedules
        daySchedule.forEach(function (day) {
            if (day.dataset.scheduleDay === target) {
                day.classList.add('is-active');
            } else {
                day.classList.remove('is-active');
            }
        });

        // Scroll to the top of the schedule
        setTimeout(() => {
            const scheduleTop = document.querySelector('#schedule');
            scheduleTop.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    });
});
